import { AUTHENTICATE, UPDATE_CONFIG, UPDATE_LOGIN_FLAG, UPDATE_SIGNUP_FLAG, UPDATE_ACTIVE_MENU,
            UPDATE_FETCH_CHANNELS, UPDATE_INITIAL_LOADING } from '../actions/auth';

const initialState = {
    config: {},
    token: null,
    profileData: null,
    mobileNumber: null,
    emailId: null,
    signInFlag: false,
    singUpFlag: false,
    activeMenu: false,
    needToFetchSubscriptions: false,
    initialLoading: true
};

export default (state = initialState, action) => {

    switch (action.type) {

        case UPDATE_CONFIG:
            return {
                ...state,
                config: action.data
            };

        case AUTHENTICATE:
            return {
                ...state,
                token: action.data.token,
                mobileNumber: action.data.mobileNumber,
                emailId: action.data.emailId,
                profileData: action.data.profile
            };

        case UPDATE_LOGIN_FLAG:
            return {
                ...state,
                signInFlag: action.data
            };

        case UPDATE_SIGNUP_FLAG:
            return {
                ...state,
                singUpFlag: action.data
            };

        case UPDATE_ACTIVE_MENU:
            return {
                ...state,
                activeMenu: action.data
            };

        case UPDATE_FETCH_CHANNELS:
            return {
                ...state,
                needToFetchSubscriptions: action.data
            };

        case UPDATE_INITIAL_LOADING:
            return {
                ...state,
                initialLoading: action.data
            };

        default:
            return state;
    }
};
