export const UPDATE_SELECTED_MEDIA = 'UPDATE_SELECTED_MEDIA';
export const UPDATE_SHARE_FLAG = "UPDATE_SHARE_FLAG";
export const UPDATE_MUSIC_PLAYER_STATUS = "UPDATE_MUSIC_PLAYER_STATUS";
export const UPDATE_PLAY_LIST = "UPDATE_PLAY_LIST";
export const UPDATE_CURRENT_TRACK_INDEX = "UPDATE_CURRENT_TRACK_INDEX";
export const UPDATE_TRACK_STATUS = "UPDATE_TRACK_STATUS";

export const updateSelectedMedia = (data) => {
    return { type: UPDATE_SELECTED_MEDIA, data: data};
};

export const updateShareFlag = (data) => {
    return { type: UPDATE_SHARE_FLAG, data: data};
};

export const updateMusicPlayerStatus = (data) => {
    return { type: UPDATE_MUSIC_PLAYER_STATUS, data: data};
};

export const updatePlayList = (data) => {
    return { type: UPDATE_PLAY_LIST, data: data};
};

export const updateCurrentTrackIndex = (data) => {
    return { type: UPDATE_CURRENT_TRACK_INDEX, data: data};
};

export const updateTrackStatus = (data) => {
    return { type: UPDATE_TRACK_STATUS, data: data};
};