import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import autheReducers from './reducers/auth';
import mediaReducers from './reducers/media';
const rootReducer = combineReducers({
    auth: autheReducers,
    media: mediaReducers
});

const consoleMessages = store => next => action => {
    
    let result;
    
    result = next(action);
    return result;
}
  
// const store = createStore(rootReducer, applyMiddleware(ReduxThunk));
export default (initialState = {}) => {
    return applyMiddleware(thunk, consoleMessages)(createStore)(rootReducer, {});
}

// export default store;