export const AUTHENTICATE = 'AUTHENTICATE';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const UPDATE_LOGIN_FLAG = 'EXPORT_LOGIN_FLAG';
export const UPDATE_SIGNUP_FLAG = 'UPDATE_SIGNUP_FLAG';
export const UPDATE_ACTIVE_MENU = "UPDATE_ACTIVE_MENU";
export const UPDATE_FETCH_CHANNELS = "UPDATE_FETCH_CHANNELS";
export const UPDATE_INITIAL_LOADING = "UPDATE_INITIAL_LOADING";

export const authenticate = (data) => {
    return { type: AUTHENTICATE, data: data};
};

export const updateConfig = (config) => {
    return { type: UPDATE_CONFIG, data: config};
};

export const updateLoginFlag = (flag) => {
    return { type: UPDATE_LOGIN_FLAG, data: flag};
};

export const updateSingupFlag = (flag) => {
    return { type: UPDATE_SIGNUP_FLAG, data: flag};
};

export const updateActiveMenu = (flag) => {
    return { type: UPDATE_ACTIVE_MENU, data: flag};
};

export const updateFetchChannels = (flag) => {
    return { type: UPDATE_FETCH_CHANNELS, data: flag};
};

export const updateInitialLoading = (flag) => {
    return { type: UPDATE_INITIAL_LOADING, data: flag};
};