import { UPDATE_SELECTED_MEDIA, UPDATE_SHARE_FLAG, 
        UPDATE_MUSIC_PLAYER_STATUS, UPDATE_PLAY_LIST, UPDATE_CURRENT_TRACK_INDEX, UPDATE_TRACK_STATUS } from '../actions/media';

const initialState = {
    selectedMedia: null,
    shareFlag: false,
    musicPlayerStatus: false,
    playList: [],
    currentTrackIndex: null,
    trackStatus: false
};

export default (state = initialState, action) => {

    switch (action.type) {

        case UPDATE_SELECTED_MEDIA:
            return {
                ...state,
                selectedMedia: action.data
            };

        case UPDATE_SHARE_FLAG:
            return {
                ...state,
                shareFlag: action.data
            };

        case UPDATE_MUSIC_PLAYER_STATUS:
            return {
                ...state,
                musicPlayerStatus: action.data
            };
            
        case UPDATE_PLAY_LIST:
            return {
                ...state,
                playList: action.data
            };

        case UPDATE_CURRENT_TRACK_INDEX:
            return {
                ...state,
                currentTrackIndex: action.data
            };

        case UPDATE_TRACK_STATUS:
            return {
                ...state,
                trackStatus: action.data
            };

        default:
            return state;

    }
};
