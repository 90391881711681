
var store = undefined;

export default {
    init(configureStore){
        store = configureStore({});
    },
    getStore(){
        return store;
    },
    getCurrentState(){
        return store.getState();
    },
    getApi(){
        var currentState = this.getCurrentState();
        return currentState.auth.config.api;
    },
    getUserInfo(){
        var currentState = this.getCurrentState();
        var data = { 
            emailId: currentState.auth.emailId, 
            mobileNo: currentState.auth.mobileNumber, 
            token: currentState.auth.token
        };
        return data;
    },
    getSessionKey(){
        var currentState = this.getCurrentState();
        return currentState.auth.config.sessionKey;
    }
};